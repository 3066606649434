/**
* Generated automatically at built-time (2024-12-10T10:31:41.201Z).
* DO NOT CHANGE IT, it will be automatically overwritten with each build.
*/
import { createLazyTemplateItem } from "../utils";
import { mergeTemplateItems } from "../common/utils";
export default function Template (props){
const defaultTemplate = [...props.widgets];
return mergeTemplateItems(defaultTemplate, props.templates).map(createLazyTemplateItem);
}
Template.mapValueToTemplateProps = {pageKey: "custom-made-abdeckung-outdoorkueche",templateKey: "sites/108-4efe0fc5-7b56-40dd-aed2-d7354da43ec8"};